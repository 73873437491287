// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as FaIcon from "./FaIcon.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as ContentBlock from "../types/ContentBlock.bs.js";
import * as MarkdownBlock from "./MarkdownBlock.bs.js";

import "./TargetContentView.css"
;

function str(prim) {
  return prim;
}

function renderBlockClasses(block) {
  var match = ContentBlock.blockType(block);
  switch (match.TAG | 0) {
    case /* Embed */3 :
        return "mt-6 pb-7";
    case /* Markdown */0 :
    case /* File */1 :
    case /* Image */2 :
    case /* Audio */4 :
        return "mt-6";
    
  }
}

function markdownContentBlock(markdown) {
  return React.createElement(MarkdownBlock.make, {
              markdown: markdown,
              profile: /* Permissive */0
            });
}

function fileContentBlock(url, title, filename) {
  return React.createElement("a", {
              "aria-label": "Download " + title,
              className: "flex justify-between bg-white border rounded-lg px-6 py-4 items-center shadow hover:border-gray-500 hover:bg-gray-50 hover:text-primary-500 hover:shadow-md focus:outline-none focus:text-primary-500 focus:ring-2 focus:ring-focusColor-500",
              href: url,
              target: "_blank"
            }, React.createElement("div", {
                  className: "flex items-center"
                }, React.createElement(FaIcon.make, {
                      classes: "text-4xl text-gray-800 far fa-file-alt"
                    }), React.createElement("div", {
                      className: "ps-4 leading-tight"
                    }, React.createElement("div", {
                          className: "text-lg font-semibold"
                        }, title), React.createElement("div", {
                          className: "text-sm italic text-gray-600"
                        }, filename))), React.createElement("div", undefined, React.createElement(FaIcon.make, {
                      classes: "text-2xl fas fa-download"
                    })));
}

function imageContentBlock(url, caption, width) {
  return React.createElement("div", {
              className: "rounded-lg bg-white text-center"
            }, React.createElement("img", {
                  className: "mx-auto w-auto md:" + ContentBlock.widthToClass(width),
                  alt: caption,
                  src: url
                }), React.createElement("div", {
                  className: "px-4 py-2 text-sm italic"
                }, caption));
}

function embedContentBlock(embedCode) {
  return React.createElement("div", {
              className: "learn-content-block__embed",
              dangerouslySetInnerHTML: {
                __html: embedCode
              }
            });
}

function audioContentBlock(url) {
  return React.createElement("audio", {
              controls: true,
              src: url
            });
}

function TargetContentView(Props) {
  var contentBlocks = Props.contentBlocks;
  return React.createElement("div", {
              className: "text-base",
              id: "learn-component"
            }, $$Array.map((function (block) {
                    var markdown = ContentBlock.blockType(block);
                    var renderedBlock;
                    switch (markdown.TAG | 0) {
                      case /* Markdown */0 :
                          renderedBlock = markdownContentBlock(markdown._0);
                          break;
                      case /* File */1 :
                          renderedBlock = fileContentBlock(markdown._0, markdown._1, markdown._2);
                          break;
                      case /* Image */2 :
                          renderedBlock = imageContentBlock(markdown._0, markdown._1, markdown._2);
                          break;
                      case /* Embed */3 :
                          renderedBlock = Belt_Option.mapWithDefault(markdown._1, null, embedContentBlock);
                          break;
                      case /* Audio */4 :
                          renderedBlock = audioContentBlock(markdown._0);
                          break;
                      
                    }
                    return React.createElement("div", {
                                key: ContentBlock.id(block),
                                className: renderBlockClasses(block)
                              }, renderedBlock);
                  }), ContentBlock.sort(contentBlocks)));
}

var make = TargetContentView;

export {
  str ,
  renderBlockClasses ,
  markdownContentBlock ,
  fileContentBlock ,
  imageContentBlock ,
  embedContentBlock ,
  audioContentBlock ,
  make ,
}
/*  Not a pure module */
